/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-scroll';

import main_header from '../../images/main_header.jpg';
import menu_header from '../../images/menu_header.jpg';
import menu_logo from '../../images/menu_logo.jpg';
import arrows from '../../images/arrows.png';
import about_header from '../../images/about_header.jpg';
import about_banner from '../../images/about_banner.jpg';
import news_header from '../../images/news_header.jpg';
import news_award from '../../images/news/award.jpg';
import news_senate from '../../images/news/senate.jpg';
import news_viva from '../../images/news/titos-food.jpg';
import news_35 from '../../images/news/news4.jpg';
import news_la from '../../images/news/tacos-screenshot.png';
import news_fn from '../../images/news/food-network.png';
import news_instagram from '../../images/news/instagram.png';
import news_commercial from '../../images/news/commercial.jpg';
import location_header from '../../images/location_header.jpg';
import map_fallback from '../../images/map_fallback.png';
import sm_fb from '../../images/facebook.jpg';
import sm_tw from '../../images/twitter.jpg';
import sm_ig from '../../images/instagram.jpg';
import sm_tb from '../../images/tumblr.jpg';

const Home = () => (
  <div id="main_site">
    <div id="header_section" className="site-section">
      <img id="main_header_img" alt="Tito's Tacos Logo" src={main_header} />
      <div id="restaurant_hours">
        <p>
          <a
            href="http://maps.apple.com/?address=11222+Washington+Place,+Culver+City,+CA+90230&t=m"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              11222 WASHINGTON PLACE
              <br />
              CULVER CITY, CA 90230
            </span>
          </a>
          <br />
          <br />
          (One block North of Washington Blvd. & West of Sepulveda Blvd.)
        </p>
      </div>
      <Link to="location_section" spy smooth offset={-40} duration={500}>
        <div className="square-button red-bg inline">
          <p>Map</p>
        </div>
      </Link>
      <div className="order-online">
        <a href="https://order.titostacos.com" target="_blank" rel="noopener noreferrer">
          <h3>ORDER ONLINE</h3>
          <img src={arrows} alt="Online Ordering Arrows" />
          <div className="order-online--buttons">
            <div className="order-online--buttons-block">
              <h4>DELIVERY</h4>
              <p>
                Order online for your DELIVERY by a Certified Food Handler, direct from Tito’s
                Kitchen to you.
              </p>
            </div>
            <div className="order-online--buttons-block">
              <h4>TAKEOUT</h4>
              <p>
                Order online before you plan to arrive to pick up your food at the Tito’s Express
                TAKEOUT Window.
              </p>
            </div>
          </div>
        </a>
      </div>
      <div id="restaurant-open-hours" className="no-top-margin">
        <h2>Restaurant Hours</h2>
        <p>
          Daily: <span className="red">11 a.m. to 8:00 p.m.</span>
        </p>
        <p>
          CLOSED <span className="red">Thanksgiving Day, Xmas Eve, Xmas Day</span>
        </p>
      </div>
      <div id="header_bottom">
        <div id="main_nav">
          <Link to="menu_section" spy smooth offset={-40} duration={500}>
            <div id="nav_menu" className="nav-item-outer">
              <div className="nav-item-inner">
                <div className="nav-text">MENU</div>
              </div>
            </div>
          </Link>
          <Link to="about_section" spy smooth offset={-60} duration={500}>
            <div id="nav_about" className="nav-item-outer">
              <div className="nav-item-inner">
                <div className="nav-text">ABOUT</div>
              </div>
            </div>
          </Link>
          <Link to="news_section" spy smooth offset={-40} duration={500}>
            <div id="nav_news" className="nav-item-outer">
              <div className="nav-item-inner">
                <div className="nav-text">NEWS</div>
              </div>
            </div>
          </Link>
          <Link to="location_section" spy smooth offset={-40} duration={500}>
            <div id="nav_location" className="nav-item-outer">
              <div className="nav-item-inner">
                <div className="nav-text">LOCATION</div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
    <div className="dotted-border">
      • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • •
      • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • •
    </div>
    <div id="menu_section" className="site-section">
      <img className="section-head-img" alt="Tito's Tacos Menu" src={menu_header} />
      <div id="menu" alt="Tito's Tacos Menu Prices">
        <div className="menu-page">
          <div className="menu-col menu-logo-col menu-side-col">
            <img src={menu_logo} alt="Titos Tacos Logo" />
          </div>
          <div className="menu-col menu-text-col">
            <h1 className="menu-header">Menu</h1>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Tito's Taco with Cheese</span>
                </div>
                <div className="item-right bolded">$5.95</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Shredded Beef, grated Cheddar Cheese & sliced Iceberg Lettuce within a freshly
                    cooked Corn Tortilla Shell, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Taco without Cheese</span>
                </div>
                <div className="item-right bolded">$4.65</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Shredded Beef & sliced Iceberg Lettuce within a freshly cooked Corn Tortilla
                    Shell, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Bean Burrito</span>
                </div>
                <div className="item-right bolded">$7.15</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Refried Beans wrapped in a hot off the grill Flour Tortilla, plus FREE Tortilla
                    Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Bean and Cheese Burrito</span>
                </div>
                <div className="item-right bolded">$8.45</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Refried Beans and freshly grated Cheddar Cheese wrapped in a hot off the grill
                    Flour Tortilla, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Bean and Cheese & Meat Sauce Burrito</span>
                </div>
                <div className="item-right bolded">$10.30</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Refried Beans and freshly grated Cheddar Cheese with savory Meat Sauce wrapped
                    in a hot off the grill Flour Tortilla, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Bean and Cheese & Rice Burrito</span>
                </div>
                <div className="item-right bolded">$10.30</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Refried Beans & grated Cheddar Cheese wrapped in a hot off the grill Flour
                    Tortilla, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Bean and Rice Burrito</span>
                </div>
                <div className="item-right bolded">$8.95</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Refried Beans and Arroz Mexicano Rice wrapped in a hot off the grill Flour
                    Tortilla, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Beef and Bean Burrito</span>
                </div>
                <div className="item-right bolded">$10.30</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Slow-cooked Chili con Carne and Refied Beans wrapped in a hot off the grill
                    Flour Tortilla, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Beef and Bean Burrito with Cheese</span>
                </div>
                <div className="item-right bolded">$11.60</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Slow-cooked Chili con Carne and Refried Beans with freshly grated Cheddar Cheese
                    wrapped in a hot off the grill Flour Tortilla, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Beef and Bean Burrito with Rice</span>
                </div>
                <div className="item-right bolded">$12.15</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Slow-cooked Chili con Carne & Refried Beans with Arroz Mexicano Rice wrapped in
                    a hot off the grill Flour Tortilla, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Beef and Bean Burrito with Cheese & Rice</span>
                </div>
                <div className="item-right bolded">$13.45</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Slow-cooked Chili con Carne & Refried Beans with freshly grated Cheddar Cheese
                    and Arroz Mexicano Rice wrapped in a hot off the grill Flour Tortilla, plus FREE
                    Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>All Beef Burrito</span>
                </div>
                <div className="item-right bolded">$13.00</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Slow-cooked Chili con Carne wrapped in a hot off the grill Flour Tortilla, plus
                    FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Beef Burrito with Cheese</span>
                </div>
                <div className="item-right bolded">$14.30</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Slow-cooked Chili con Carne with freshly grated Cheddar Cheese wrapped in a hot
                    off the grill Flour Tortilla, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Beef Burrito with Rice</span>
                </div>
                <div className="item-right bolded">$14.85</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Slow-cooked Chili con Carne with Arroz Mexicano Rice wrapped in a hot off the
                    grill Flour Tortilla, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>All Beef Burrito with Rice & Cheese</span>
                </div>
                <div className="item-right bolded">$16.15</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Slow-cooked Chili con Carne with Arroz Mexicano Rice & freshly grated Cheddar
                    Cheese wrapped in a hot off the grill Flour Tortilla, plus FREE Tortilla Chips &
                    Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Chicken and Bean Burrito</span>
                </div>
                <div className="item-right bolded">$9.30</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Seasoned Chicken and Refried Beans wrapped in a hot off the grill Flour
                    Tortilla, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Chicken and Bean Burrito with Cheese</span>
                </div>
                <div className="item-right bolded">$10.60</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Seasoned Chicken and Refried Beans with freshly grated Cheddar Cheese wrapped in
                    a hot off the grill Flour Tortilla, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Chicken and Bean Burrito with Rice</span>
                </div>
                <div className="item-right bolded">$11.15</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Seasoned Chicken and Refried Beans with Arroz Mexicana Rice wrapped in a hot off
                    the griddle Flour Tortilla, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Chicken and Bean Burrito with Cheese & Rice</span>
                </div>
                <div className="item-right bolded">$12.45</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Seasoned Chicken and Refried Beans with Cheddar Cheese and Arroz Mexicana Rice
                    wrapped in a Flour Tortilla, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>All Chicken Burrito</span>
                </div>
                <div className="item-right bolded">$12.00</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Seasoned Chicken wrapped in a hot off the griddle Flour Tortilla, plus FREE
                    Tortilla Chips and Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Chicken Burrito with Cheese</span>
                </div>
                <div className="item-right bolded">$13.30</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Seasoned Chicken with Cheddar Cheese wrapped in a hot off the griddle Flour
                    Tortilla, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Chicken Burrito with Rice</span>
                </div>
                <div className="item-right bolded">$13.85</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Seasoned Chicken with Arroz Mexicana Rice wrapped in a hot off the griddle Flour
                    Tortilla, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>All Chicken with Rice & Cheese</span>
                </div>
                <div className="item-right bolded">$15.15</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    All Seasoned Chicken with Arroz Mexicana Rice and Cheddar Cheese wrapped in a
                    hot off the griddle Flour Tortilla, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="menu-col menu-page-col menu-side-col">page 1</div>
        </div>
        <div className="menu-page">
          <div className="menu-col menu-logo-col menu-side-col">
            <img src={menu_logo} alt="Titos Tacos Logo" />
          </div>
          <div className="menu-col menu-text-col">
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Veggie Tamale</span>
                </div>
                <div className="item-right bolded">$8.05</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Fresh cooked Vegetables wrapped in fine ground Masa combined with Pinto Beans
                    and steamed to perfection in Corn Husk, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Chicken Tamale</span>
                </div>
                <div className="item-right bolded">$8.05</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Grilled Cilantro Chicken and Anaheim Green Chili covered in savory Meat Sauce,
                    plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Enchilada</span>
                </div>
                <div className="item-right bolded">$6.50</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Top quality Cheddar Cheese wrapped in a Corn Tortilla and drenched in flavorful
                    Enchilada Sauce, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Tostada</span>
                </div>
                <div className="item-right bolded">$6.50</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Crispy Corn Tortilla topped in hot refried Beans, sliced Iceberg Lettuce and
                    graded Cheddar Cheese, plus FREE Tortilla Chips & Salsa
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Chili Beans</span>
                </div>
                <div className="item-right bolded">(8oz) $5.65</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>100% Ground Beef & Pinto Beans slow-cooked in a mild Chili Sauce</span>
                </div>
                <div className="item-right bolded">(16oz) $11.25</div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Refried Beans</span>
                </div>
                <div className="item-right bolded">(8oz) $3.90</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Slow cooked Pinto Beans which are refried to achieve flavorsome results
                  </span>
                </div>
                <div className="item-right bolded">(16oz) $7.55</div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Fiesta Refried Bean Pan</span>
                </div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Small (Serves 12 to 15 people)</span>
                </div>
                <div className="item-right bolded">$56.05</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Large (Serves around 30 people)</span>
                </div>
                <div className="item-right bolded">$112.05</div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Chili Con Carne</span>
                </div>
                <div className="item-right bolded">(8oz) $11.95</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Slow-cooked Chili con Carne with south of the border flavor to savor</span>
                </div>
                <div className="item-right bolded">(16oz) $23.70</div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Fiesta Chili Con Carne Pan</span>
                </div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Small (Serves 12 to 15 people)</span>
                </div>
                <div className="item-right bolded">$196.00</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Large (Serves around 30 people)</span>
                </div>
                <div className="item-right bolded">$391.60</div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Chili Con Pollo</span>
                </div>
                <div className="item-right bolded">(8oz) $10.95</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Slow-cooked Chili con Pollo with south of the border flavor to savor</span>
                </div>
                <div className="item-right bolded">(16oz) $22.70</div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Rice</span>
                </div>
                <div className="item-right bolded">(8oz) $3.90</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Arroz Mexicana Rice with a charateristic assortment of traditional seasonings
                  </span>
                </div>
                <div className="item-right bolded">(16oz) $7.55</div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Fiesta Rice Pan</span>
                </div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Small (Serves 12 to 15 people)</span>
                </div>
                <div className="item-right bolded">$56.05</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Large (Serves around 30 people)</span>
                </div>
                <div className="item-right bolded">$112.05</div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Tortilla Chips</span>
                </div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Freshly sliced and fried Corn Tortilla Chips</span>
                </div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Small</span>
                </div>
                <div className="item-right bolded">$2.90</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Medium</span>
                </div>
                <div className="item-right bolded">$5.65</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Large (bag)</span>
                </div>
                <div className="item-right bolded">$11.25</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Box</span>
                </div>
                <div className="item-right bolded">$12.00</div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Red Salsa</span>
                </div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Finely diced Red Tomatoes combined with a unique mixture of Herbs, Seasonings
                  </span>
                </div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Extra Small</span>
                </div>
                <div className="item-right bolded">$1.50</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Small (4oz)</span>
                </div>
                <div className="item-right bolded">$2.90</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Medium (8oz)</span>
                </div>
                <div className="item-right bolded">$5.65</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Large (16oz)</span>
                </div>
                <div className="item-right bolded">$11.25</div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Green Chili Salsa</span>
                </div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Delectable chopped Green Chili from Texas intermixed with a distinctive blend of
                    Herbs, Seasonings and Spices
                  </span>
                </div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Extra Small</span>
                </div>
                <div className="item-right bolded">$1.50</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Small (4oz)</span>
                </div>
                <div className="item-right bolded">$2.90</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Medium (8oz)</span>
                </div>
                <div className="item-right bolded">$5.65</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Large (16oz)</span>
                </div>
                <div className="item-right bolded">$11.25</div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Tito's Guacamole</span>
                </div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Mashed up ripe Avocados delicately blended with a traditional array of Herbs,
                    Seasonings and Spices
                  </span>
                </div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Small (4oz)</span>
                </div>
                <div className="item-right bolded">$3.90</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Medium (8oz)</span>
                </div>
                <div className="item-right bolded">$7.50</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Large (16oz)</span>
                </div>
                <div className="item-right bolded">$14.90</div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Flan Dessert</span>
                </div>
                <div className="item-right bolded">$5.60</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Amazing Traditional Mexican Desert</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Soft Drinks</span>
                </div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Pepsi, Diet-Pepsi, Pepsi Zero, Orange, Mug Root Beer, Starry, Pink Lemonade,
                    Mountain Dew, Brisk Iced Tea
                  </span>
                </div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Small (18oz)</span>
                </div>
                <div className="item-right bolded">$2.20</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Large (22oz)</span>
                </div>
                <div className="item-right bolded">$3.10</div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Starbucks Frappuccino</span>
                </div>
                <div className="item-right bolded">$5.00</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Mocha, Caramel and Coffee Flavors Available</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Horchata</span>
                </div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Traditional Mexican Drink famous for bringing together generations of Mexican
                    food aficionados at mealtime
                  </span>
                </div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Small (18oz)</span>
                </div>
                <div className="item-right bolded">$2.20</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Large (22oz)</span>
                </div>
                <div className="item-right bolded">$3.10</div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Pina</span>
                </div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Small (18oz)</span>
                </div>
                <div className="item-right bolded">$2.20</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Large (22oz)</span>
                </div>
                <div className="item-right bolded">$3.10</div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Jamaica</span>
                </div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Small (18oz)</span>
                </div>
                <div className="item-right bolded">$2.20</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Large (22oz)</span>
                </div>
                <div className="item-right bolded">$3.10</div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Coffee</span>
                </div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Aromatic Columbian Coffee</span>
                </div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Small</span>
                </div>
                <div className="item-right bolded">$1.40</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Large</span>
                </div>
                <div className="item-right bolded">$2.75</div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Milk (1/3pt)</span>
                </div>
                <div className="item-right bolded">$1.70</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>2% Homogenized Milk</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Water</span>
                </div>
                <div className="item-right bolded">$3.10</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Purified H2O</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Tito's Tacos Trucker Hat</span>
                </div>
                <div className="item-right bolded">$25.00</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Black Trucker Hat with Tito's Tacos Logo</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Tito's Tacos Classic Cap</span>
                </div>
                <div className="item-right bolded">$35.00</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>Yellow Hat with Embroidered Bill Design & Logo</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>Tito's Tacos T-Shirt (Small, Medium, Large, XL, XXL)</span>
                </div>
                <div className="item-right bolded">$25.00</div>
              </div>
              <div className="item-line">
                <div className="item-left">
                  <span>
                    Black T-shirt with Tito's Tacos Logo for Customers with really good taste
                  </span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-line">
                <div className="item-left">
                  <span>*All prices Subject to Change without Notice</span>
                </div>
              </div>
            </div>
          </div>
          <div className="menu-col menu-page-col menu-side-col">page 2</div>
        </div>
      </div>
      <div>
        {/* <h1>
            <a
              href="https://www.titostacos.com/pdf/titos_menu.pdf"
              target="_blank"
              rel="noopener noreferrer"
              alt="Tito's Tacos Menu Prices Download"
            >
              Download Menu PDF
            </a>
          </h1> */}
        <div className="online-mod red-bg">
          <h2>PLACE FOOD ORDER HERE!</h2>
          <a
            href="https://order.titostacos.com"
            target="_blank"
            rel="noopener noreferrer"
            className="btn-yellow"
          >
            Order
          </a>
        </div>
        <p className="txt1">
          Must present Major Credit Card and Photo Identification of Card Holder when picking up all
          Online Food Orders at Tito’s Tacos Mexican Restaurant.
        </p>
      </div>
      <div className="dotted-border">
        • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • •
        • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • •
      </div>
      <div id="about_section" className="site-section">
        <h1>For People with Really Good Taste!</h1>
        <img
          className="standalone-header-img section-head-img"
          src={about_header}
          alt="About Tito's Tacos"
        />
        <div id="about_banner">
          <img src={about_banner} alt="Photos of Tito's Tacos" />
        </div>
        <div id="about_info">
          <p>
            Since 1959, Tito’s Tacos has provided their beloved customers with delicious Mexican
            Food prepared fresh daily based upon the restaurant’s original recipes.
          </p>
          <p>
            Their award-winning Mexican Food Cuisine has become a longtime favorite for people from
            all walks of life and cultural backgrounds desiring a south-of-the-border eating
            experience without leaving the greater Los Angeles area.
          </p>
          <p>
            Tito’s Tacos employees are fully trained in how to properly and healthfully prepare each
            and every menu item in order to achieve the utmost in flavorsome results for people with
            really good taste.
          </p>
        </div>
      </div>
    </div>
    <div className="dotted-border">
      • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • •
      • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • •
    </div>
    <div id="news_section" className="site-section">
      <img className="section-head-img news" src={news_header} alt="Tito's Tacos News" />
      <div className="news-item quote">
        <div className="news-image-container">
          <img src={news_award} alt="Customers waiting in line at Tito's Tacos" />
          <div className="caption">©Photo by: Jared Cowan</div>
        </div>
        <div className="news-text-container">
          <div className="news-headline quote">
            <h1>
              "California Restaurant Association Foundation Selects Tito’s Tacos for 2017 Restaurant
              Neighbor Award"
              <br />
              <span>Culver City Observer</span>
            </h1>
          </div>
          <div className="news-button">
            <a href="https://www.culvercityobserver.com/story/2017/04/27/news/california-restaurant-association-foundation-selects-titos-tacos-for-2017-restaurant-neighbor-award/6644.html">
              READ MORE
            </a>
          </div>
        </div>
      </div>
      <div className="news-item quote">
        <div className="news-image-container">
          <img src={news_senate} alt="Tito’s Tacos Wins Recognition on Small Business Day" />
        </div>
        <div className="news-text-container">
          <div className="news-headline">
            <h1>
              "Tito’s Tacos Wins Recognition
              <br />
              on Small Business Day"
              <br />
              <span>Culver City Crossroads</span>
            </h1>
          </div>
          <div className="news-button">
            <a href="http://culvercitycrossroads.com/2018/06/20/titos-tacos-wins-recognition-on-small-business-day/">
              READ MORE
            </a>
          </div>
        </div>
      </div>
      <div className="news-item quote">
        <div className="news-image-container">
          <img src={news_viva} alt="Tito's Tacos with a drink and salsa" />
        </div>
        <div className="news-text-container">
          <div className="news-headline">
            <h1>
              "Serving up Good Food and Good Deeds – viva Tito’s Tacos!"
              <br />
              <span>Culver City Observer</span>
            </h1>
          </div>
          <div className="news-button">
            <a
              href="https://www.titostacos.com/pdf/serving-up-good-food-and-good-deals.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              READ MORE
            </a>
          </div>
        </div>
      </div>
      <div className="news-item quote">
        <div className="news-image-container">
          <img src={news_35} alt="Tito's Burrito with Chips and Salsa" />
        </div>
        <div className="news-text-container">
          <div className="news-headline">
            <h1>
              America’s 35 Best Burritos 2015’ (Tito’s Burrito selected Number One in America)"
              <br />
              <span>Daily Meal</span>
            </h1>
          </div>
          <div className="news-button">
            <a href="https://www.thedailymeal.com/eat/america-s-35-best-burritos-2015/slide-36">
              READ MORE
            </a>
          </div>
        </div>
      </div>
      <div className="news-item quote">
        <div className="news-image-container">
          <img src={news_la} alt="Screen grab of Insider's Tito's Tacos video" />
        </div>
        <div className="news-text-container">
          <div className="news-headline">
            <h1>
              "How Tito’s Tacos Became LA’s Favorite Taco"
              <br />
              <span>Food Insider</span>
            </h1>
          </div>
          <div className="news-button">
            <a href="https://www.youtube.com/watch?v=w2fzboe5BbE">VIEW VIDEO</a>
          </div>
        </div>
      </div>
      <div className="news-item quote">
        <div className="news-image-container">
          <img src={news_fn} alt="Screen grab from Food Network's Tito's Tacos video" />
        </div>
        <div className="news-text-container">
          <div className="news-headline">
            <h1>
              ‘Deep FRIED Tacos from Tito’s Tacos’
              <br />
              <span>Food Network</span>
            </h1>
          </div>
          <div className="news-button">
            <a href="https://www.youtube.com/watch?v=fzbrlAbJl-4">VIEW HERE</a>
          </div>
        </div>
      </div>
      <div className="news-item quote">
        <div className="news-image-container">
          <img src={news_instagram} alt="Jack Black eating Tito's Tacos" />
        </div>
        <div className="news-text-container">
          <div className="news-headline">
            <h1>
              "cheat day breakfast –<br />
              Tito’s Tacos edition" (Jack Black)
              <br />
              <span>Instagram</span>
            </h1>
          </div>
          <div className="news-button">
            <a href="https://www.youtube.com/watch?v=nN27OUoXu2A">VIEW HERE</a>
          </div>
        </div>
      </div>
      <div className="news-item">
        <div className="news-image-container">
          <img src={news_commercial} alt="Tito's Tacos commercial" />
        </div>
        <div className="news-text-container">
          <div className="news-headline">
            <h1>
              Tito’s Tacos Commercial
              <br />
              English & Spanish Versions
            </h1>
            <p />
          </div>
          <div className="news-button">
            <a href="https://www.youtube.com/watch?v=BH9aEe8qXZM">VIEW ENGLISH HERE</a>
            <a href="https://www.youtube.com/watch?v=1MYBV9ub4CI">VIEW SPANISH HERE</a>
          </div>
        </div>
      </div>
      <div className="dotted-border">
        • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • •
        • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • •
      </div>
      <div id="location_section" className="site-section">
        <h1>Tito’s Tacos Mexican Restaurant, Inc.</h1>
        <img
          className="standalone-header-img section-head-img"
          src={location_header}
          alt="Tito's Tacos Location"
        />
        <a
          href="http://maps.apple.com/?address=11222+Washington+Place,+Culver+City,+CA+90230&t=m"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div id="location_map">
            <img id="location_fallback_image" src={map_fallback} alt="Tito's Tacos Map" />
          </div>
          <div id="location_info">
            <p>
              11222 WASHINGTON PLACE
              <br />
              CULVER CITY, CA 90230
            </p>
          </div>
        </a>
        <div id="contact_info">
          <a href="tel:1-310-391-5780">
            <div id="contact_email" className="contact-block">
              <div className="contact-block-inner">
                <div className="square-button red-bg">Call Us</div>
                <div id="contact_email_text" className="contact-text">
                  Tel 310.391.5780
                </div>
              </div>
            </div>
          </a>
          <div id="no_phone_orders">Sorry, No Phone Orders</div>
          <a href="fax:1-310-391-9655">
            <div id="contact_fax" className="contact-block">
              <div className="contact-block-inner">
                <div className="square-button">Fax Us</div>
                <div className="contact-text">Fax 310.391.9655</div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className="dotted-border">
        • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • •
        • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • •
      </div>
      <div id="footer_section" className="site-section">
        <div id="social">
          <a href="https://www.facebook.com/titostacosUSA">
            <img src={sm_fb} alt="Tito's Tacos on Facebook" />
          </a>
          <a href="https://twitter.com/TitosTacos">
            <img src={sm_tw} alt="Tito's Tacos on Twitter" />
          </a>
          <a href="https://instagram.com/titostacosusa/">
            <img src={sm_ig} alt="Tito's Tacos on Instagram" />
          </a>
          <a href="https://titostacosusa.tumblr.com/">
            <img src={sm_tb} alt="Tito's Tacos on Tumblr" />
          </a>
        </div>
        <h1 id="motto">Gran Sabor desde 1959</h1>
        <div id="legal_info">
          <p id="privacy_notice">
            <span className="red">
              *
              <a
                href="https://www.titostacos.com/pdf/privacy.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Notice
              </a>
            </span>
          </p>
          <p>© Tito’s Tacos, 2022 / titostacos.com</p>
        </div>
      </div>
    </div>
  </div>
);

export default Home;
