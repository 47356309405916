/* eslint-disable react/function-component-definition */
import React from 'react';
import ReactGA from 'react-ga';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import Home from '../Home/Home';

ReactGA.initialize('UA-144468724-2');
ReactGA.pageview(window.location.pathname + window.location.search);

const Layout = () => (
  <Router>
    <div>
      <Switch>
        <Route path="/" component={Home} />
      </Switch>
    </div>
  </Router>
);

export default Layout;
